import '../config.js'
import '../scss/main.scss'

import '../js/helpers.js'
import '../libs/popper/popper.js'
import '../js/bootstrap.js'
import '../libs/node-waves/node-waves.js'
import '../libs/perfect-scrollbar/perfect-scrollbar.js'
import '../libs/hammer/hammer.js'
import '../libs/toastr/toastr'
import select2 from 'select2';

import jQuery from 'jquery/dist/jquery';
(window as any).jQuery = jQuery;
//@ts-ignore
import("jquery-ui/dist/jquery-ui");
//@ts-ignore
select2();

import '../main.js'

